import { Room } from "./room";
import { l3 } from "./loadMesh";

var matrix;
/* questa funzione main ritorna la matrice, genera le stanze e stampa il risultato per debug */
export function generateRooms(matrixSize, debug, scene) {


 
  
  var size = matrixSize + 2; // dimensione aggiornata per il padding (evitare undefined)
  matrix = new Array(size);
  // genero matrice di zeri
  generateMatrix(size);

  // occupo roomNumber celle della matrice

  /*
  for (var i = 0; i < roomNumber; i++) {
    insertOccupiedSpace(size);
    
  }

  */




  // genero la stanza effettiva
  
  for (var i = 1; i < matrix.length-1; i++) {
    for (var j = 1; j < matrix[i].length-1; j++) {
       
        matrix[j][i] = new Room(scene, j, i, size, matrix, Math.floor(Math.random()*60) );
    }
  }
  // passo la matrice di zeri e stanze
  for (var i = 1; i < matrix.length-1; i++) {
    for (var j = 1; j < matrix[i].length-1; j++) {
      if (matrix[j][i] != 0) matrix[j][i].matrix = matrix;
    }
  }
  // genero i muri 
  for (var i = 1; i < matrix.length-1; i++) {
    for (var j = 1; j < matrix[i].length-1; j++) {
    
      
        matrix[j][i].createWalls();
     
    }
  }
  if (debug) roomDebug(size);
  return matrix;
}

/* questa funzione genera una matrice di 0 (stanze libere) */
function generateMatrix(size) {
  // creo la matrice di zeri (stanze vuote)
  for (var i = 0; i < matrix.length; i++) {
    matrix[i] = new Array(size);
    for (var j = 0; j < matrix[i].length; j++) {
      matrix[i][j] = 0;
    }
  }
}

var start = 0;
var occupiedRooms = [];
var last = [];

// se è vuota creo il primo spazio centrale
function insertOccupiedSpace(size) {
  var openList = []; 
  if (start == 0) {
    matrix[Math.floor(size / 2)][Math.floor(size / 2)] = 1;
    start++;
    //inserisco la prima stanza occupata
    occupiedRooms.push([Math.floor(size / 2), Math.floor(size / 2)]);
  } else {
    var k = 0;
    if(occupiedRooms.length > 30) {
      
    }
   
    for(var i =  k; i < occupiedRooms.length; i++) {
      var room = occupiedRooms[i];
    
     
      if(matrix[room[0]][room[1]-1] == 0) { openList.push([room[0], room[1]-1]);   }
      if(matrix[room[0]+1][room[1]] == 0) { openList.push([room[0]+1, room[1]]);  }
      if(matrix[room[0]][room[1]+1] == 0) { openList.push([room[0], room[1]+1]);  }
      if(matrix[room[0]-1][room[1]] == 0) { openList.push([room[0]-1, room[1]]);  }
    
     
    }
    /*
    scrollable.forEach((room) => {
     
      if(matrix[room[0]][room[1]-1] == 0) { openList.push([room[0], room[1]-1]);   }
      if(matrix[room[0]+1][room[1]] == 0) { openList.push([room[0]+1, room[1]]);  }
      if(matrix[room[0]][room[1]+1] == 0) { openList.push([room[0], room[1]+1]);  }
      if(matrix[room[0]-1][room[1]] == 0) { openList.push([room[0]-1, room[1]]);  }
      
    });
    */
   
    // scelgo uno spazio /rappresentato da un vettore a due valori) a caso tra quelli liberi e connessi
    var randomRoomCoords =
      openList[Math.floor(Math.random() * openList.length)];
    // lo occupo
    matrix[randomRoomCoords[0]][randomRoomCoords[1]] = 1;
    
    occupiedRooms.push([randomRoomCoords[0], randomRoomCoords[1]]);
  
  }
}

function roomDebug(size) {
  var String = "";
  var hehe = 0;
  for (var z = 0; z < size; z++) {
    for (var x = 0; x < size; x++) {
     
      matrix[x][z] == 0 ? String+= 0 : String+= 1;
    }
    String += "\n";
  }
  console.log(String); 
}




