import * as THREE from "three";
import { altezzaStanza, larghezzaStanza } from "../configurazione";
export const geometryPavimento = new THREE.PlaneBufferGeometry(
    larghezzaStanza,
    larghezzaStanza,
    1,
    1
  );

  export const geometryMuro = new THREE.PlaneBufferGeometry(
    larghezzaStanza,
    altezzaStanza,
    1,
    1
  );
