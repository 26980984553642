import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { scene } from "./script";
import { detailOpen } from "./script";
const canvasDetail = document.querySelector(".canvas-detail")
const sizesDetail = {
    width: window.innerWidth > 600 ? window.innerWidth*(70/100) :  window.innerWidth,
    height:  window.innerWidth > 600 ? window.innerHeight : window.innerHeight/2,
  };
const rendererDetail = new THREE.WebGLRenderer({
    canvas: canvasDetail,
    antialias: true,
    powerPreference: "low-power",
    alpha: true
  });
  rendererDetail.setSize(sizesDetail.width, sizesDetail.height);
  rendererDetail.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  rendererDetail.shadowMap.enabled = true;
  rendererDetail.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap
  
  export const sceneDetail = new THREE.Scene();

 var cameraDetail = new THREE.PerspectiveCamera(
    45,
    sizesDetail.width / sizesDetail.height,
    1,
    1000
  );
cameraDetail.position.set(0,3,4);
cameraDetail.lookAt(0,0,0);
  // create a geometry
var controls = new OrbitControls(cameraDetail, rendererDetail.domElement);
const geometry = new THREE.PlaneBufferGeometry(100,100,16,16);

// create a default (white) Basic material
const material = new THREE.MeshBasicMaterial(
  {wireframe: true}
);
material.side = THREE.DoubleSide;
material.roughness = 1;
material.color = new THREE.Color(0.9,0.9,0.9);

// create a Mesh containing the geometry and material
const cube = new THREE.Mesh(geometry, material);

cube.rotation.set(Math.PI/2,0,0);
export var lastMesh;
export var detailMesh;
export var isRunning = false;



var lightz = new THREE.PointLight(new THREE.Color(0xffffff), 0.3, 60, 1);
var amb = new THREE.AmbientLight(new THREE.Color(0xffffff), 1);
lightz.position.set(10,20,0);
 lightz.castShadow = false;
lightz.shadow.bias = -0.01;
lightz.shadow.mapSize.x = 1024;
lightz.shadow.mapSize.y = 1024;
// add the mesh to the scene
sceneDetail.add(lastMesh);
sceneDetail.add(lightz);
sceneDetail.add(amb);
sceneDetail.add(cube);




  export function animate() {
 
    if(isRunning) {
    controls.update();
    rendererDetail.render(sceneDetail, cameraDetail);
   
    requestAnimationFrame(animate);
    }
   
  }

export function stop() {
  isRunning = false;
}




export function opereSlideshow(mopere, autore) {

  console.log(mopere);
  document.getElementById("slideshow").innerHTML = "";
  for(var i = 0; i < mopere.length; i++) {
     if(mopere[i].autore === autore) {
     
       for(var j = 0; j < mopere[i].listaOpereAutore.length; j++) {
        let opera = mopere[i].listaOpereAutore[j];
        document.getElementById("slideshow").innerHTML += "<div> \
        <img   \
        src=\"artworks/"+opera.percorso+"\" \
        title=\""+opera.opera+"\" \
        description=\""+opera.descrizione+"\" \
        class=\"slideimage\"> \
        </div>";

       }
     
       break;
     }
  }

}


export function updateInformation(element) {
       
  document.getElementById("atitle").textContent = element.getAttribute('title');
  document.getElementById("description").textContent = element.getAttribute('description');
}



export function placeMesh(mesh) {
    isRunning = true;
    animate();
    // resetto la telecamera nella scene dettaglio
    cameraDetail.position.set(4,4,8);
    cameraDetail.lookAt(0,0,0);
    cameraDetail.rotation.set(0,0,0);
    controls = new OrbitControls(cameraDetail, rendererDetail.domElement);
    
    if(mesh.three) {
       
        amb.intensity = 0.3;
        lightz.intensity = 1;
    }
    else {
        amb.intensity = 1;
        lightz.intensity = 0;
    }
    try {
      
    if(lastMesh.video) {
     
      lastMesh.video.pause();
    }
    lastMesh.material.map.dispose();
    lastMesh.material.dispose();
    lastMesh.geometry.dispose();
   
   
    }
    catch(error) {
      
    }
    sceneDetail.remove(lastMesh);
      // creo la nuova mesh
      lastMesh = mesh.clone();
      lastMesh.castShadow = true;
      lastMesh.position.set(0,0,0);
      lastMesh.rotation.set(0,0,0);
      sceneDetail.add(lastMesh);
      if(mesh.video) {
       lastMesh.video = mesh.video;
       let videoMaterial = new THREE.MeshLambertMaterial();
       videoMaterial.map = new THREE.Texture(mesh.video);
       lastMesh.video.play();
      
      }

     

}