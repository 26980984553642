import * as THREE from "three";
import { BoxBufferGeometry } from "three";
import { BufferGeometryUtils } from "three/examples/jsm/utils/BufferGeometryUtils.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { materialMuro } from "./materials/roomMaterials";
import {
  larghezzaStanza,
  altezzaStanza,
  altezzaOriginale,
  larghezzaOriginale,
  isMobile,
} from "./configurazione";
import { cartellaImmagini } from "./configurazione";
import { Room } from "./room";
const loader = new GLTFLoader();
const gardenloader = new GLTFLoader();
var textureLoader = new THREE.TextureLoader();
// primo tipo di muro
var single;
// secondo tipo di muro
var multi;
// tetto
var tetto;

// carico asincronamente il muro
function l1(path) {
  return new Promise((resolve, reject) => {
    loader.load(path, (data) => resolve(data));
  });
}

// ritorno il secondo muro
export async function tiles() {
  single = [await l1("door.glb")];
  multi = [await l1("multidoor.glb"), await l1("multidoor2.glb")];
  tetto = [await l1("tetto.glb")];

  //se voglio caricare materiali interni
  single.forEach((tile, index) => {
    single[index] = tile.scene;
    single[index].traverse((o) => {
      if (o.isMesh) {
        o.material = materialMuro;
        o.castShadow = true;
        o.receiveShadow = true;
      }
    });
  });
  multi.forEach((tile, index) => {
    multi[index] = tile.scene;
    multi[index].traverse((o) => {
      if (o.isMesh) {
        o.material = materialMuro;
        o.castShadow = true;
        o.receiveShadow = true;
      }
    });
  });
  tetto.forEach((tile, index) => {
    tetto[index] = tile.scene;
    tetto[index].traverse((o) => {
      if (o.isMesh) {
        o.material = materialMuro;
        o.castShadow = true;
        o.receiveShadow = true;
      }
    });
  });

  return new Promise((resolve, reject) => {
    resolve(true);
  });
}

// funzione che viene eseguita per ogni muro presente nel museo. attenzione = in maniera asincrona
// si occupa anche di posizionare correttamente e scalare il muro. per qualche motivo, l'import asincrono sballa le altezze
export function loadWall(mscene, path, x, y, z, rotation) {
  var n;
  if (path === "door.glb") {
    var n = single[Math.floor(Math.random() * single.length)].clone();
  } else if (path === "multidoor.glb") {
    var n = multi[Math.floor(Math.random() * multi.length)].clone();
  }
  n.scale.set(
    larghezzaStanza / larghezzaOriginale,
    altezzaStanza / altezzaOriginale,
    larghezzaStanza / larghezzaOriginale
  );
  n.position.set(x, y, z);
  n.rotation.set(0, rotation, 0);
  mscene.add(n);
}

// questo metodo serve a generare pavimenti o soffitti particolari
export function loadFloor(mscene, path, xmaterial, x, y, z, rotation) {
  var mmesh;
  loader.load(
    path,
    function (gltf) {
      mmesh = gltf.scene;
      mmesh.scale.set(
        larghezzaStanza / larghezzaOriginale,
        1,
        larghezzaStanza / larghezzaOriginale
      );
      mmesh.rotation.set(0, 0, 0);
      mmesh.position.set(x, y, z);
      // mmesh.receiveShadow = true;
      //  mmesh.castShadow = true;
      mmesh.traverse((o) => {
        if (o.isMesh) {
          o.material = xmaterial;
          o.castShadow = true;
          o.receiveShadow = true;
        }
      });
      mscene.add(mmesh);
    },
    undefined,
    function (error) {
      console.error(error);
    }
  );
}

// questo metodo serve a caricare un modello 3D GLB e a crearne una bounding box ottimizzata per collisioni o interazioni
export function remover(obj, scene) {
  scene.remove(obj);
}
export function loadTexture(path) {
  return new Promise((resolve, reject) => {
    textureLoader.load(path, (data) => resolve(data));
  });
}
// carico video e immagini sui muri
export async function load2DMesh(info, x, z, rot, roomscene) {
  let Operasize = isMobile ? 2 : 2.5;
  let ratio = 1;
  var texture;
  var mmaterial;
  var video;
  var geometryOpera;
  if (info.tipo === "video") {
    video = document.createElement("video");
    video.playsInline = true;
    video.id = info.percorso;
    video.src = cartellaImmagini + info.percorso;
    texture = new THREE.VideoTexture(video);
    ratio = 4 / 3;
    mmaterial = new THREE.MeshStandardMaterial({
      map: texture,
    });
    
  geometryOpera = new THREE.BoxBufferGeometry(
    Operasize * ratio,
    Operasize,
    0.01
  );


  } 
  
  else if (info.tipo === "immagine") {
  
    texture = await loadTexture(cartellaImmagini + info.percorso);
    ratio = texture.image.naturalWidth / texture.image.naturalHeight;
    mmaterial = new THREE.MeshStandardMaterial({
      map: texture,
    });
    
  geometryOpera = new THREE.BoxBufferGeometry(
    Operasize * ratio,
    Operasize,
    0.01
  );


  
  }

  let opera = new THREE.Mesh(geometryOpera, mmaterial);
  opera.interact = true;
  opera.collision = false;
  opera.rotation.set(0,rot,0);
  opera.position.set(x,isMobile ? 1.8 : 2.4, z);
  opera.artType = "artwork";
  opera.title = info.opera;
  opera.author = info.autore;
  opera.description = info.descrizione;
  if(info.tipo === "video") {
    opera.video = video;
  }
  roomscene.add(opera);

}












// carico un modello 3D

export function load3DMesh(info, x, z, roomscene) {
  loader.load(cartellaImmagini + info.percorso, (object) => {
    // dimensioni della bounding box
    var box = new THREE.Box3(
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, 0, 0)
    );
    // mesh per il mouse tracing
    var mesh = new THREE.Mesh(new BoxBufferGeometry(1, 1, 1), materialMuro);

    var obj = object.scene;
    // inserimento dei dati visibili
    obj.position.set(x, 0, z);
    obj.collision = false;
    obj.artType = "artwork";
    obj.three = true;
    obj.title = info.opera;
    obj.author = info.autore;
    obj.description = info.descrizione;
    obj.model = info.percorso;

    obj.artID = info.artID;

    // alias boundingbox per gestire le interazioni
    mesh.interactable = obj;
    mesh.visible = false;
    mesh.interact = true;
    mesh.collision = false;
    obj.traverse((o) => {
      if (o.isMesh) {
        o.castShadow = true;
        o.receiveShadow = true;
        var tempbox = new THREE.Box3().setFromObject(o);

        if (volume(tempbox) > volume(box)) {
          box = new THREE.Box3().setFromObject(o);
        }
      }
    });

    let mx = box.max.x - box.min.x;
    let my = box.max.y - box.min.y;
    let mz = box.max.z - box.min.z;
    mesh.scale.set(
      box.max.x - box.min.x,
      box.max.y - box.min.y,
      box.max.z - box.min.z
    );
    var mvolume = volume(box);

    if (mvolume > 512) {
      let max = Math.max(mx, my, mz);

      obj.scale.set(6 / max, 6 / max, 6 / max);
      obj.appliedScale = 8 / max;
      box = new THREE.Box3().setFromObject(obj);
      mesh.scale.set(
        box.max.x - box.min.x,
        box.max.y - box.min.y,
        box.max.z - box.min.z
      );
    }

    mesh.position.set(x, (box.max.y - box.min.y) / 2, z);
    roomscene.add(obj);
    roomscene.add(mesh);
  });
}

function volume(tempbox) {
  return (
    (tempbox.max.x - tempbox.min.x) *
    (tempbox.max.y - tempbox.min.y) *
    (tempbox.max.z - tempbox.min.z)
  );
}

export function loadGarden(mscene) {
  gardenloader.load(
    "garden/garden.glb",
    function (gltf) {
      var mmesh = gltf.scene;
      // mmesh.rotation.set(0, 0, 0);
      //  mmesh.position.set(x, 0, z);
      mmesh.receiveShadow = true;
      mmesh.traverse((o) => {
        if (o.isMesh) {
          o.material = materialMuro;
          o.castShadow = true;
          o.receiveShadow = true;
        }
      });
      mscene.add(mmesh);
    },
    undefined,
    function (error) {
      console.error(error);
    }
  );
}

var treeModel = [];

function loadTree() {
  return new Promise((resolve, reject) => {
    gardenloader.load("garden/tree.glb", (data) => resolve(data));
  });
}
export async function awaitTree() {
  var x = await loadTree();

  x = x.scene;
  x.traverse((o) => {
    if (o.isMesh) {
      treeModel.push(o);
    }
  });

  return new Promise((resolve, reject) => {
    resolve(true);
  });
}

var trees = [];
export function placeTree(scene, num) {
  var g;
  let ref = new THREE.Mesh(
    new THREE.BoxBufferGeometry(10, 10, 10, 2, 2, 2),
    new THREE.MeshStandardMaterial({
      color: 0xff0000,
    })
  );
  ref.position.set(0, 5, 0);
  scene.add(ref);
  for (var i = 0; i < num; i++) {
    var scale = (Math.random() + 0.5) / 20;
    var randpX = (Math.random() - 0.5) * 2 * 100;
    var randpZ = Math.random() * 2 * 100;

    treeModel.forEach((treeParts) => {
      var n = new THREE.Mesh(treeParts.geometry, treeParts.material);
      n.receiveShadow = true;
      n.castShadow = true;
      n.position.set(randpX, 0, randpZ);
      n.rotation.set(Math.PI / 2, 0, 0);
      n.scale.set(scale, scale, scale);

      trees.push(n.geometry);
    });

    var geo = BufferGeometryUtils.mergeBufferGeometries(trees);
    var m = new THREE.Mesh(geo, materialMuro);
    scene.add(m);
  }
}
