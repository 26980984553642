import "./style.css";

import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import * as dat from "dat.gui";
import { generateRooms } from "./roomMatrix";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { FilmPass } from "three/examples/jsm/postprocessing/FilmPass.js";
import { loadCSV, createCSV } from "./loadCSV";
import {
  materialGreen,
  shaderWater,
  updateMap,
  updateShader,
} from "./materials/roomMaterials";
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass.js";
import { remove, set, update } from "firebase/database";
import {
  artPathReference,
  temporaryID,
  users,
  ws,
  database,
  updateDB,
  deleteDB,
} from "./database/databaseConfig";
import { ref, get } from "firebase/database";
import { MD5 } from "crypto-js";
import { load3DMesh, tiles } from "./loadMesh";
import { animate, lastMesh, opereSlideshow } from "./canvasDetail";
// per il giardino
import { loadGarden, awaitTree, placeTree } from "./loadMesh";

// cose generiche
import { placeMesh, stop } from "./canvasDetail";

import {
  visbilita,
  larghezzaStanza,
  distr,
  isMobile,
  maxOpere,
  optimizerValue,
} from "./configurazione";
import { Vector3 } from "three";

animate();
// TODO: Add SDKs for Firebase products that you want to use
THREE.Cache.enabled = true;
const canvas = document.querySelector("canvas.webgl");
/**
 * Renderer
 */

var mobile = window.innerWidth < 1024 ? true : false;
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
  powerPreference: "low-power",
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap
// renderer.toneMapping = THREE.ReinhardToneMapping;

// scenes
const scene = new THREE.Scene();
const gardenScene = new THREE.Scene();
var scenes = [scene, gardenScene];
scene.mouseRaycasters = [];
scene.collisionRaycasters = [];
var cullingSide = 4;

// shared lights
var light = true;
var lIntensity = {
  value: 1,
};
scene.background = new THREE.Color(
  lIntensity.value,
  lIntensity.value,
  lIntensity.value
);

scene.fog = new THREE.FogExp2(
  new THREE.Color(lIntensity.value, lIntensity.value, lIntensity.value),
  0.03
);
//gardenScene.fog = scene.fog;

/*
 * Camera
 */
// Base camera
if (isMobile) {
  camera = new THREE.PerspectiveCamera(
    85,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );
} else
  camera = new THREE.PerspectiveCamera(
    40,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );
camera.position.x = 3;
camera.position.y = 1.65;
camera.position.z = 3;
scene.add(camera);
// gardenScene.add(camera);

// POSTPROCESSING

const composer = new EffectComposer(renderer);
const renderPass = new RenderPass(scenes[currentscene], camera);
composer.addPass(renderPass);
// const film = new FilmPass(0.1, 0.1, 0.1, 0.3);
var outlinePass = new OutlinePass(
  new THREE.Vector2(window.innerWidth, window.innerHeight),
  scene,
  camera
);
var outlinePass2 = new OutlinePass(
  new THREE.Vector2(window.innerWidth, window.innerHeight),
  scene,
  camera
);
outlinePass2.edgeStrength = 20;
outlinePass2.visibleEdgeColor = new THREE.Color(0x000000);
outlinePass.edgeStrength = 10;
outlinePass.pulsePeriod = 1;
outlinePass.visibleEdgeColor = new THREE.Color(0x00ffff);
// composer.addPass(film);
// composer.addPass(film);
composer.addPass(outlinePass);
composer.addPass(outlinePass2);
scene.outline = outlinePass2;

var cubeCamera;
if (!mobile && currentscene == 0) {
  var cubeRenderTarget1 = new THREE.WebGLCubeRenderTarget(2048, {
    format: THREE.RGBFormat,
    generateMipmaps: true,
  });

  cubeCamera = new THREE.CubeCamera(1, visbilita, cubeRenderTarget1);
  scene.add(cubeCamera);
  updateMap(cubeCamera.renderTarget.texture);
}
// Scene

var userLight = new THREE.PointLight(0xffffff, 0.4, 60, 2);

scene.add(userLight);

// scena del museo
if (currentscene == 0) {
  var matrix = createMuseum();

  /** LUCI --------------------------------------------------------------------------- */
 
  var c1 = new THREE.Color("#A9D6F8");
  var c2 = new THREE.Color("#2570A6");

  // luci ambientali

  var ambientlight = new THREE.AmbientLight(0xffffff, 0.96);

  let isSun = false;
  if (isSun) {
    var sun2 = new THREE.DirectionalLight(0xffffff, 0.5);

    sun2.position.set(0, 100, -100);

    sun2.shadow.bias = -0.01;
    sun2.shadow.mapSize.x = 1024;
    sun2.shadow.mapSize.y = 1024;
    sun2.castShadow = true;

    scene.add(sun2);
    scene.add(ambientlight);
  } else {
    scene.add(ambientlight);
  }
}

// GIARDINO
else {
  var ambientlight = new THREE.AmbientLight(0xffffff, lIntensity.value * 0.5);
  gardenScene.add(ambientlight);
  gardenScene.mouseRaycasters = [];
  gardenScene.collisionRaycasters = [];
  gardenScene.trees = [];
  var sun = new THREE.PointLight(0xffeeee, 1);
  var sun2 = new THREE.DirectionalLight(0xffcccc, 0.7);
  // sun.target = camera;
  sun.position.set(50, 20, 10);
  sun2.position.set(-100, 100, 10);
  sun2.target = camera;
  sun.shadow.bias = -0.01;
  sun.shadow.mapSize.x = 2048;
  sun.shadow.mapSize.y = 2048;
  sun.castShadow = true;
  gardenScene.add(sun);
  //gardenScene.add(sun2);

  gardenScene.collisionRaycasters = [];
  loadGarden(gardenScene);

  async function startTree() {
    var x = await awaitTree();

    var scale = Math.random() + 0.2;
    placeTree(
      gardenScene,

      1000
    );
  }
  startTree();
  const loader = new THREE.TextureLoader();

  const bgTexture = loader.load("sky.jpg");
  gardenScene.background = bgTexture;
  gardenScene.add(userLight);
}

const clock = new THREE.Clock();
var time = 0; // TEMPO PER IL MOVIMENTO
camera.frustum = new THREE.Frustum();
var cursorX = 0;
var cursorY = 0;
var sx = window.innerWidth / 2;
const raycaster = new THREE.Raycaster();
const raycasterBehind = new THREE.Raycaster();
var lookAt = [];
const raycasterM = new THREE.Raycaster();
var intersects = []; // collisioni frontali
var intersectsMouse = [];
var intersectsBack = []; // collisioni dietro
const mouse = new THREE.Vector2();
export var detailOpen = false;
// ---- variabili movimento
var positionZ = 3; // MOVIMENTO CAMERA
var positionX = 3; // MOVIMENTO CAMERA
var angle = 0; // MOVIMENTO CAMERA
var vec1;
var vec2;
var goforward = false; // check collisioni e movimento
var gobackwards = false; // check collisioni e movimento
var turnLeft = false;
var turnRight = false;
const mobileRotationStrength = mobile ? 1 : 1;

var dist = 10000; // Valore random per le distanze
var dist2 = 10000;

var selectedObj; // oggetto selezionato per le informazioni
var myPi = Math.PI / 180;
// debug velocità
var t = 0; //interazioni attuali
var frame = 0; //frame per smaltire il render
var maxTime = 0; // tempo massimo registrato
var totalTime = 0; // tempo totale trascorso
var innerWidth = window.innerWidth;
var avgTime = 0;
var audio = new Audio("sounds/exploration.mp3");
var walk = new Audio("sounds/wlk.mp3");
var clickaudio = new Audio("sounds/click.mp3");
audio.volume = 0.1;

walk.volume = 0.4;
clickaudio.volume = 0.4;

// abilita il raytracing solo se gira bene
var optimizer = false;
var startOptimizer = true;
var avgFPS = 0;
var speedMultiplier = 0;
var calculatedFPS = 0;
// per lo scambio di scene
var isRunningMain = true;

// FUNZIONE LOOOP

//utilizzata per memorizzare percorsi degli utenti
var positions = [];


var down = false;

function tick() {
  // tempo usato per le animazioni di camminata
  time += 0.1;
  speedMultiplier = clock.getDelta()*80;
 

 // materialGreen.opacity = map(Math.sin(time/2), -1, 1, 0, 0.2); 
  var elapsedTime = clock.getElapsedTime();

  // avvio
  if (t == 80) {
    $("#loader").addClass("started");
  }
  /*
  if (false) {
    try {
      //  console.log("sent");
      ws.send(
        JSON.stringify([
          temporaryID,
          camera.position.x,
          camera.position.z,
          Date.now(),
        ])
      );
    } catch (e) {
      console.log(e);
    }
  }
 
   if(t%60 == 0) {
    set(
      reference,
      {  
      position: [camera.position.x, camera.position.z] }
    
    )
      
  
  }
     

  if (t % 600 == 0) {
    positions.push(camera.position.x, camera.position.z);
    set(artPathReference, {
      position: positions,
    });
  }
 */
  if (!goforward && !gobackwards) {
    walk.pause();
  }

  // Raycasting per le animazioni

  raycasterBehind.set(camera.position, lookAt);
  raycaster.setFromCamera(new THREE.Vector2(0, 0), camera);
  intersects = raycaster.intersectObjects(
    scenes[currentscene].collisionRaycasters,
    true
  );
  intersectsBack = raycasterBehind.intersectObjects(
    scenes[currentscene].collisionRaycasters,
    true
  );

  raycasterM.setFromCamera(mouse, camera);

  intersectsMouse = raycasterM.intersectObjects(
    scenes[currentscene].mouseRaycasters,
    true
  );

  // svuoto la lista di elementi interattivi
  scenes[currentscene].mouseRaycasters = [];
  scenes[currentscene].collisionRaycasters = [];

  // selezione dell'oggetto puntato dal mouse
  outlinePass.selectedObjects = [];

  // oggetto normale
  if (!insertEmpty) {
    if (intersectsMouse[0]) {
      //in caso di mesh 3D per la collisione
      if (intersectsMouse[0].object.interactable) {
        selectedObj = intersectsMouse[0].object.interactable;
      } else {
        selectedObj = intersectsMouse[0].object;
      }

      outlinePass.selectedObjects = [selectedObj];
      document.getElementById("select").classList.add("active");
      document.getElementById("select-name").classList.add("active");
      document.getElementById("select-name-detail").textContent =
        selectedObj.title;

      if (selectedObj.video && selectedObj.video.paused) {
        document.getElementById("select").textContent = "Play";
      } else if (/*selectedObj.video && !selectedObj.video.paused */ false) {
        document.getElementById("select").textContent = "Stop";
      } else {
        document.getElementById("select").textContent = "Click";
      }
    } else {
      selectedObj = null;
      document.getElementById("select").classList.remove("active");
      document.getElementById("select-name").classList.remove("active");
    }
  }

  // luce che accompagna il giocatore
  userLight.position.set(camera.position.x, 3, camera.position.z);

  // renderizzo le stanze vicine
  if (currentscene == 0) {
    posX = Math.ceil(
      map(
        camera.position.x,
        (-larghezzaStanza * matrix.length) / 2,
        (larghezzaStanza * matrix.length) / 2,
        0,
        matrix.length
      )
    );
    posZ = Math.ceil(
      map(
        camera.position.z,
        (-larghezzaStanza * matrix.length) / 2,
        (larghezzaStanza * matrix.length) / 2,
        0,
        matrix.length
      )
    );

    var frustummatrix = new THREE.Matrix4().multiplyMatrices(
      camera.projectionMatrix,
      camera.matrixWorldInverse
    );
    camera.frustum.setFromProjectionMatrix(frustummatrix);

    for (
      var x = posX - cullingSide;
      x < posX + cullingSide && x < matrix.length;
      x++
    ) {
      for (
        var z = posZ - cullingSide;
        z < posZ + cullingSide && z < matrix.length;
        z++
      ) {
        if (x > 0 && z > 0 && matrix[x][z] != 0) {
          matrix[x][z].frustumRender(
            camera.frustum,
            visbilita,
            frame,
            camera.position.x,
            camera.position.z
          );
        }
      }
    }
  }

  // scena giardino (??)
  /*
  else {
    scenes[currentscene].trees.forEach((tree) => {
      if (
        distance(
          camera.position.x,
          camera.position.z,
          tree.position.x,
          tree.position.z
        ) < 40
      ) {
        scenes[currentscene].add(tree);
      } else scenes[currentscene].remove(tree);
    });
  }
  */
  // update tempo per gli shader GLSL
  updateShader();

  /** MOVIMENTI  */
  vec1 = new THREE.Vector2(positionX, positionZ);
  vec2 = new THREE.Vector2(
    -positionX - 10000 * Math.sin(angle * myPi),
    positionZ + 10000 * Math.cos(angle * myPi)
  );
  vec1.x = vec1.x - vec2.x;
  vec1.y = vec1.y - vec2.y;
  vec1.normalize();

  // booleano per gestire se è in stato di collisione
  dist = intersects[0] ? intersects[0].distance : 1000;
  // booleano per gestire le collisioni dietro alla schiena
  dist2 = intersectsBack[0] ? intersectsBack[0].distance : 1000;
 

  move();

  let select = document.getElementById("select");
  let selectName = document.getElementById("select-name");
  let ssx = cursorX + window.innerWidth / 2;
  let ssy = cursorY + window.innerHeight / 2;
  select.style.transform =
    "translate3d( calc( -50% + " + ssx + "px), calc( -50% + " + ssy + "px),0)";
  if(isMobile) {
    selectName.style.transform =
    "translate3d(" + (ssx - 110) + "px, " + (ssy - 80) + "px,0)";
  }
  else {
  selectName.style.transform =
    "translate3d(" + (ssx + 70) + "px, " + (ssy - 70) + "px,0)";
  }

  // risposizionamento telecamera
  camera.position.x = positionX;
  camera.position.z = positionZ;
  // valori opposti verso cui punta la telecamera. utilizzato per osservare dietro
  lookAt = new THREE.Vector3(
    -(-camera.position.x - 10000 * Math.sin(angle * myPi)),
    lookAt[1],
    lookAt[2],
    mobile ? -1.65 : -(-cursorY * 10 + Math.sin(time) * 0.05),
    -(-camera.position.z + 10000 * Math.cos(angle * myPi))
  ).normalize();
  // valori verso cui punta la telecamera.
  camera.lookAt(
    -camera.position.x - 10000 * Math.sin(angle * myPi),
    mobile ? 1.65 : -cursorY * 10 + Math.sin(time) * 0.05,
    -camera.position.z + 10000 * Math.cos(angle * myPi)
  );


  // SPEED DEBUG
  var el = clock.getElapsedTime();
  var percentage = 0;
  totalTime += el - elapsedTime;
  avgTime = totalTime / t;
  if (elapsedTime > maxTime && el > 5) maxTime = elapsedTime;
  // document.getElementById("debug").innerHTML = scene.mouseRaycasters.length;
  var percentage = Math.floor((t / 80) * 100);
  if (percentage > 100) percentage = 100;
  if (percentage < 100) {
    document.getElementById("percentage-value").textContent = percentage + "%";
  } else {
    document.getElementById("percentage-value").textContent =
      "An idea by M. Fabbro and L. Doremi";
  }

  document.getElementById("percentage-bar").style.width = percentage + "%";

  composer.render();

  let framerate = Math.min(
    Math.floor(1000 / ((clock.getElapsedTime() - elapsedTime) * 1000)),
    120
  );
  if (!distr) {
    document.getElementById("debug").textContent =
      1000 / ((clock.getElapsedTime() - elapsedTime) * 1000);
    // document.getElementById("debug").textContent = framerate;
  }
  t++;
  frame++;
  if (frame == 60) {
    frame = 0;
  }
  decideRaytrace(
    framerate,
    1000 / ((clock.getElapsedTime() - elapsedTime) * 1000)
  );
  // document.getElementById("debug").textContent = dist2;
  // se solo questa finestra è aperta, continua i frame
  if (isRunningMain) {
    window.requestAnimationFrame(tick);
  }
}

function decideRaytrace(framerate, avgTime) {
  if (t >= 60 && t < 140) {
    avgFPS += framerate;
    calculatedFPS++;
  }
  if (t == 140) {
    avgFPS = avgFPS / calculatedFPS;

    if (avgFPS < optimizerValue) {
      optimizer = true;
      updateMap(null);
    }
  }

 
}

// eventi vari e movimento

var rect = renderer.domElement.getBoundingClientRect();

document.onmousemove = function (e) {
  mouse.x = (e.clientX / rect.width) * 2 - 1;
  mouse.y = -(e.clientY / rect.height) * 2 + 1;
  cursorX = e.pageX - window.innerWidth / 2;
  cursorY = e.pageY - window.innerHeight / 2;
  if (!mobile) sx = e.pageX;

  if (audio.ended) {
    audio.play();
  }
};

document.addEventListener("keydown", logKey);
document.addEventListener("keyup", releaseKey);
window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;
  innerWidth = window.innerWidth;
  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();
  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  rect = renderer.domElement.getBoundingClientRect();
});

// WASD
function logKey(e) {
  if (audio.paused) audio.play();
  if (walk.paused || walk.ended) walk.play();
  if (e.keyCode == 87) {
    goforward = true;
  } else if (e.keyCode == 83) {
    gobackwards = true;
  }

  if (e.keyCode == 65) {
    turnLeft = true;
  }
  if (e.keyCode == 68) {
    turnRight = true;
  }
}

function releaseKey(e) {
  if (e.keyCode == 87) {
    goforward = false;
  } else if (e.keyCode == 83) {
    gobackwards = false;
  }
  if (e.keyCode == 65) {
    turnLeft = false;
  }
  if (e.keyCode == 68) {
    turnRight = false;
  }
}

// COMANDI VIRTUALI SCHERMO PER CELLULARE O COMPUTER IN CASO

$(".control").on("touchstart mousedown", function (e) {
  if (audio.ended) {
    audio.play();
  }
  if (walk.paused) walk.play();

  var attribute = $(this).attr("value");

  if (attribute == "W") {
    goforward = true;
  }
  if (attribute == "A") {
    turnLeft = true;
  }
  if (attribute == "S") {
    gobackwards = true;
  }
  if (attribute == "D") {
    turnRight = true;
  }

  e.preventDefault(); //prevents further events from being dispatched
});

$(".control").on("touchend mouseup", function (e) {
  //your code...

  var attribute = $(this).attr("value");

  if (attribute == "W") {
    goforward = false;
  }
  if (attribute == "A") {
    turnLeft = false;
  }
  if (attribute == "S") {
    gobackwards = false;
  }
  if (attribute == "D") {
    turnRight = false;
  }

  e.preventDefault(); //prevents further events from being dispatched
});




// INTERAZIONI CON IL SITO WEB
document.getElementById("select").addEventListener("touchstart", (e) => {
  e.preventDefault;
});

// Si apre la schermata 3D
$("#start").click(function () {
  clickaudio.play();
  if (!isMobile) {
    $("#loader").addClass("vanish");
    $("#percentage").addClass("vanish");
    audio.play();
  }
});

$("#start").on("touchstart", function (e) {
  clickaudio.play();
  $("#loader").addClass("vanish");
  $("#percentage").addClass("vanish");
  audio.play();
});






// apro la schermata delle informazioni
$("#select").on("touchstart", function (e) {
 
  objectInfo();
});
$("#select").on("mousedown", function (e) {
 
  if (!isMobile) {
    objectInfo();
  }
});

// smetto di muovermi
$("canvas").on("touchend", function (e) {
  goforward = false;
});
$("#select").on("mouseup", function (e) {
  if (!isMobile) {
    goforward = false;
  }
});


function move() {

  
  // eseguo i movimenti solo se è chiuso il menu dei dettagli
  if (!detailOpen && !insertEmpty) {
    if (dist < 2) {
      goforward = false;
    } else if (goforward) {
      positionX -= 0.08 * speedMultiplier * vec1.x;
      positionZ -= 0.08 * speedMultiplier * vec1.y;
    }

    if (dist2 < 2) {
      gobackwards = false;
    } else if (gobackwards) {
      positionX += 0.08 * speedMultiplier * vec1.x;
      positionZ += 0.08 * speedMultiplier * vec1.y;
    } else {
    }

    // cambio posizione ruotando la vista
    if (turnLeft) {
      angle -= 1 * mobileRotationStrength * speedMultiplier;
    } else if (turnRight) {
      angle += 1 * mobileRotationStrength * speedMultiplier;
    }

    if (!isMobile && (sx < innerWidth / 3 || sx > innerWidth - innerWidth / 3))
      angle += mouse.x;
  }


    // muovo la testa
    if (goforward || gobackwards) {
      camera.position.y = 1.65 + Math.sin(time) * 0.05;
      if (!isMobile && currentscene == 0 && !optimizer) {
        cubeCamera.position.y = 0 - Math.sin(time) * 0.05;
      }
    }
  
    // se non è mobile e ottimizzato attivo il raytracing
    if (!isMobile && currentscene == 0 && !optimizer) {
      cubeCamera.update(renderer, scene);
      cubeCamera.position.x = camera.position.x;
      cubeCamera.position.z = camera.position.z;
    }
  
}




// prepara il DOM
function objectInfo() {
  // inserisco un'opera in uno spazio vuoto
  if (selectedObj && (selectedObj.empty || selectedObj.empty3D)) {
    $("#upload").addClass("opened");
    selectedObj.empty3D
      ? (document.getElementById("file-input").accept = ".glb, .gltf")
      : (document.getElementById("file-input").accept = ".jpg,.jpeg,.png,.mp4");
    
    emptyObject = selectedObj;
    insertEmpty = true;
  }




  // mostro i dettagli dell'opera
  else if (selectedObj && !detailOpen) {
    placeMesh(selectedObj);
    isRunningMain = false;
    //  opereSlideshow(truedata, selectedObj.author);
    $("#information").scrollTop(0);
    detailOpen = true;
    document.getElementById("information").style.transform = "translateY(0%)";

    document.getElementById("atitle").textContent = selectedObj.title;
    document.getElementById("aauthor").textContent = selectedObj.author;
    document.getElementById("description").textContent =
      selectedObj.description;
    // document.getElementById("art-id").textContent =
    //   "ID opera = " + selectedObj.artID;
    // se è un'immagine carico o nascondo l'immagine
    if (typeof selectedObj.source !== "undefined") {
      //  document.getElementById("aartworkimg").style.display = "block";
      //   document.getElementById("aartworkimg").src = selectedObj.source;
    } else {
      //   document.getElementById("aartworkimg").style.display = "none";
    }
  }

  //movimento via mouse
  else if (!detailOpen && !isMobile) {
    goforward = true;
  }
}

// chiude il menu detail dell'opera d'arte
$("#close").on("click touchstart", function () {
  document.getElementById("information").style.transform = "translateY(100%)";
  detailOpen = false;
  if (lastMesh.video) {
    lastMesh.video.pause();
  }
  stop();
  isRunningMain = true;
  tick();
});

// funzioni di supporto

function map(number, inMin, inMax, outMin, outMax) {
  return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}

// questa funzione si occupa sia di caricare i file, generare le stanze ed infine inserire le opere
async function createMuseum() {
  //deleteDB();
  // updateDB();
  var started = await tiles();
  // conto il numero di modelli 3D, che rappresentano anche il numero minimo di stanze
  var models = [];
  //mydata = await get(ref(database, "Main/artworks/"));
 

  if (distr) {
    console.log("Il museo sta venendo avviato!");
    var sqlData = [];

    const xmlhttp = new XMLHttpRequest();
    xmlhttp.onload = async function () {
      const myObj = JSON.parse(this.responseText);

      sqlData = myObj;
      sqlData.forEach((object) => {
        mydata.push(object);
        if (object.tipo === "3D") {
          models.push(object);
        }
      });

      loadMuseum(mydata, models);
    };

    xmlhttp.open("GET", "datajson.php");
    xmlhttp.send();
  } else loadMuseum(mydata, models);
}

function distance(x, y, x2, y2) {
  return Math.sqrt(Math.pow(x - x2, 2) + Math.pow(y - y2, 2));
}

async function loadMuseum(mydata, models) {
  if (distr) {
  } else {
    var input = await loadCSV("CSV/opereDebug.csv");
    mydata = createCSV(input);
  }
  var loadData = await get(ref(database, "Main/artworks/")).then((snapshot) => {
    if (snapshot.exists()) {
      snapshot.forEach((row, index) => {
        mydata.push(row.val());
        if (row.val().tipo === "3D") {
          models.push(row.val());
        }
      });
    }
  });
 
  
  truedata = mydata;
 
  if (mydata.length > maxOpere) {
    truedata = [];
    for (var i = 0; i < maxOpere; i++) {
      truedata.push(mydata[Math.floor(Math.random() * mydata.length)]);
    }
    mydata = [];
  }

  // divido le opere per autori
  /*
  let authors = [];
  truedata.forEach((object) => {
    // autore nuovo
    if (!authors.includes(object.autore)) {
      authors.push(object.autore);
      let lopere = [];
      // aggiungo la prima opera alla lista corrispondere all'autore
      lopere.push(object);
      autdata.push({ autore: object.autore, listaOpereAutore: lopere });
    } 
    // autore già presente
    else {
     
      let op = 0;
      for (var i = 0; i < opere.length; i++) {
        if(autdata[i].autore === object.autore) {
          autdata[i].listaOpereAutore.push(object);
          break;
        }
      
      }
     
     
    }
  


  });
 */





  var roomNumber = Math.ceil(truedata.length / 3.5);

  if (models.length > roomNumber) {
    roomNumber = models.length;
  }

  var side = Math.ceil(Math.sqrt(roomNumber));

  // stanze vere
  var rooms = [];
  matrix = generateRooms(side, false, scene);
  // salvo le stanze vere
  for (var i = 0; i < matrix.length; i++) {
    for (var j = 0; j < matrix.length; j++) {
      if (matrix[i][j] != 0) {
        rooms.push(matrix[i][j]);
      }
    }
  }

  // indice randomico che inverte le stanze
  var flips = [];
  var spaces = 0;
  rooms.forEach((room, index) => {
    // calcolo gli spazi presenti in ogni stanza
    spaces += room.calculateSpace();
    flips.push(index);
  });

  // creo un array randomico di posizioni
  flips.forEach((flip, index) => {
    var bucket = flip;
    var flipPos = Math.floor(Math.random() * flips.length);
    flips[index] = flips[flipPos];
    flips[flipPos] = bucket;
  });

  // assegno tutte le opere

  for (var i = 0; i < truedata.length; i++) {
    let curr = flips[i % rooms.length];
    var selectedRoom = rooms[curr];
 
    if (truedata[i].tipo !== "3D") {

      // cerco una stanza libera successiva
      while (selectedRoom.remainingSpaces <= 0) {
        curr++;
        selectedRoom = rooms[curr % rooms.length];
      }

      selectedRoom.setArtwork(truedata[i]);
    }
  }

  // assegno tutti i modelli 3D
  models.forEach((model, index) => {
    var selectedRoom = rooms[flips[index % rooms.length]];
    if (selectedRoom.remaining3DSpaces > 0) {
      selectedRoom.setArtwork(model);
    }
  });

  // assegno uno spazio vuoto per stanza
  if (true) {
    rooms.forEach((room) => {
      while (room.remainingSpaces > 1) {

        room.setArtwork("empty");
        room.remainingSpaces--;
      }
      // assegno qualche modello 3D vuoto in giro
      if ( !isMobile && room.remaining3DSpaces > 0) {
        let rand = Math.floor(Math.random() * 10);
        if (rand < 3) {
          room.setArtwork("empty3D");
          room.remaining3DSpaces--;
        }
      }

      // console.log(room.remainingSpaces);
      /*if(roomNumber < 1000) {
      
      room.create();
      
    }
    */
    });
  }
}
tick();
