
// dimensioni all'interno di ThreeJS
export var larghezzaStanza = 15;
export var altezzaStanza = 10;
// dimensioni originale dei tile in Blender
export var larghezzaOriginale = 15;
export var altezzaOriginale = 3;
export var isMobile = window.innerWidth < 900;
// ottimizzazione sistema
export var visbilita = 50;
export var optimizerValue = 90;
export var maxOpere = 1000;
// cartelle debug
export var fileOpere = "CSV/opere.csv"
export var cartellaImmagini = "artworks/"
export var myxlsx = "CSV/debug10A.xlsx"
// Per la versione online. false per non usare PHP 
export var distr = true;