import * as THREE from "three";
import {
  larghezzaStanza,
  altezzaStanza,
  isMobile,
  cartellaImmagini,
} from "./configurazione.js";
import { load2DMesh, load3DMesh, loadTexture, loadFloor, loadWall, mmesh, remover } from "./loadMesh.js";
import {
  material,
  materialGreen,
  materialMuro,
  shaderWater,
} from "./materials/roomMaterials.js";
import { geometryPavimento, geometryMuro } from "./meshes/roomMeshes.js";
import { BufferGeometryUtils } from "three/examples/jsm/utils/BufferGeometryUtils";


export class Room {
  constructor(scene, x, z, side, matrix, frame) {
    this.scene = scene;
    this.roomScene = new THREE.Group();
    this.added = false;
    // valore utilizzato per spalmare il render su diversi frame
    this.frame = frame;
    // stanze connesse in caso servano
    this.top = null;
    this.left = null;
    this.right = null;
    this.bottom = null;
    // muri posseduti
    this.topWall = { built: false, type: null };
    this.leftWall = { built: false, type: null };
    this.rightWall = { built: false, type: null };
    this.bottomWall = { built: false, type: null };
    // array di valori che indicano se vi è un muro su,giu,sx o dx
    this.spaces = [];

    this.remainingSpaces; //spazi rimanenti
    this.remaining3DSpaces = 1;
    this.closedSpaces = 0; // in caso di muri chiusi
    // opere possedute
    this.artworks = [];
    // dimensioni
    this.width = larghezzaStanza;
    this.height = altezzaStanza;
    this.offset = Math.floor(side / 2);
    // posizione nella matrice
    this.matrixX = x;
    this.matrixZ = z;
    // posizione fisica
    this.x = this.width * (x - this.offset);
    this.z = this.width * (z - this.offset);
    this.matrix = matrix;

    // variabili generiche
    this.randfloor = Math.floor(Math.random() * 10);
    // utilizzato per indicare una stanza mai stata creata prima
    this.newr = true;
  }
  // metodo chiamato solo la prima volta che viene istanziata la stanza
  create() {
    // PAVIMENTO O SOFFITTO STANZA
    var roof = new THREE.Mesh(geometryPavimento, materialMuro);
    roof.rotation.set(-Math.PI / 2, 0, 0);
    roof.position.set(this.x, this.height, this.z);
    if (/*this.randfloor*/ true) {
      var plane = new THREE.Mesh(geometryPavimento, material);
      plane.sharedGeometry = true;

      plane.rotation.set(-Math.PI / 2, 0, 0);
      plane.position.set(this.x, 0, this.z);

      plane.receiveShadow = true;
      this.roomScene.add(plane);
    } else {
      loadFloor(this.roomScene, "piscina.glb", material, this.x, 0, this.z);

      /*
      var splane = new THREE.Mesh(geometryAcqua, shaderWater);

      splane.rotation.set(-Math.PI / 2, 0, 0);
      splane.position.set(this.x, -0.5, this.z);

      splane.receiveShadow = true;
       this.roomScene.add(splane);
       */
    }
    
    if(this.randfloor > 8)
      { loadFloor(
          this.roomScene,
          "tetto.glb",
          materialMuro,
          this.x,
          altezzaStanza+1,
          this.z);
          if(!isMobile) {
       //   let l = new THREE.PointLight(0xffffff, 1, 30, 2);
       //   l.position.set(this.x, this.height + 3, this.z)
        //  this.roomScene.add(l);
          }
      }
      else this.roomScene.add(roof);
     
    this.placeArtwork();
    this.newr = false;
  
  
  }

  // questo serve ad eseguire un frustum culling
  async frustumRender(frustum, distance, frame, x, z) {

   

    let roomDistance = this.distance(this.x, this.z, x, z);

   /* let containsPoint = frustum.containsPoint(
      new THREE.Vector3(this.x, 0, this.z)
    );
    */
    let inRange = roomDistance < distance * (this.width / 15);


    // selezione oggetto selezionato dal mouse
    if (roomDistance < distance - 10) {
      this.roomScene.children.forEach((el) => {
        if (el.collision === undefined) this.scene.collisionRaycasters.push(el);
        if (el.interact) {
          this.scene.mouseRaycasters.push(el);
        }
      });
    }

    if(this.frame == frame) { 
    // se la stanza non è già presente
    if (
      !this.added &&
      (roomDistance < distance /*||
        (roomDistance < distance + 15 && containsPoint) */)
    ) {
      // se la stanza non è mai stata creata
      if (this.newr) {
        this.create();
        this.newr = false;
        
      }

      this.scene.add(this.roomScene);
     
      this.added = true;
    } else if (
      
      this.added &&
      roomDistance > distance /* &&
       !containsPoint */
    ) {
      this.added = false;
     
      this.roomScene.children.forEach((child) => {
        // elimino dalla memoria solo le opere, non i dati dei muri
        if (child.artType === "artwork") {
          try {
            if (child.geometry) child.geometry.dispose();

            if (child.material) {
              if (child.material.map) {
                child.material.map.dispose();
              }
              if (child.material.normalMap) {
                child.material.normalMap.dispose();
              }
              if (child.material.roughnessMap) {
                child.material.roughnessMap.dispose();
              }
              child.material.dispose();
            }
          } catch (e) {}
        }
      });
      this.scene.remove(this.roomScene);
    }

  }
  }

   distance(x, y, x2, y2) {
    return Math.sqrt(Math.pow(x - x2, 2) + Math.pow(y - y2, 2));
  }

  // genero i muri in base alla matrice delle posizioni
  createWalls() {
    // muro sopra
    // controllo se c'è una stanza. se non c'è (0), allora genero un muro chiuso
    // poi controllo se esiste già un porta in quella stanza
    if (this.matrix[this.matrixX][this.matrixZ - 1] == 0) {
      this.wall(0, -this.width / 2, 0, false, this.topWall);
    } else {
      this.top = this.matrix[this.matrixX][this.matrixZ - 1];
      if (!this.top.bottomWall.built) {
        this.wall(
          0,
          -this.width / 2,
          0,
          true,
          this.topWall,
          this.top,
          this.top.bottomWall
        );
      } else this.closedSpaces++;
    }
    // muro destra (ruotare)
    if (this.matrix[this.matrixX + 1][this.matrixZ] == 0) {
      this.wall(this.width / 2, 0, Math.PI / 2, false, this.rightWall);
    } else {
      this.right = this.matrix[this.matrixX + 1][this.matrixZ];
      if (!this.right.leftWall.built) {
        this.wall(
          this.width / 2,
          0,
          Math.PI / 2,
          true,
          this.rightWall,
          this.right,
          this.right.leftWall
        );
      } else this.closedSpaces++;
    }
    // muro sotto (ruotare)
    if (this.matrix[this.matrixX][this.matrixZ + 1] == 0) {
      this.wall(0, this.width / 2, 0, false, this.bottomWall);
    } else {
      this.bottom = this.matrix[this.matrixX][this.matrixZ + 1];
      if (!this.bottom.topWall.built) {
        this.wall(
          0,
          this.width / 2,
          0,
          true,
          this.bottomWall,
          this.bottom,
          this.bottom.topWall
        );
      } else this.closedSpaces++;
    }

    // muro sinistra (ruotare)
    if (this.matrix[this.matrixX - 1][this.matrixZ] == 0) {
      this.wall(-this.width / 2, 0, Math.PI / 2, false, this.leftWall);
    } else {
      this.left = this.matrix[this.matrixX - 1][this.matrixZ];
      if (!this.left.rightWall.built) {
        this.wall(
          -this.width / 2,
          0,
          Math.PI / 2,
          true,
          this.leftWall,
          this.left,
          this.left.rightWall
        );
      } else this.closedSpaces++;
    }
  }

  // genero fisicamente il muro
  wall(xOffset, zOffset, rotation, door, wallblock, neighbor, neighborwall) {
    // muro solido senza porte esterno

    /*
    i type di muro servono ad identificare quanti spazi occupano:

    wall = due spazi
    singledoor = due spazi (passaggio)
    multidoor = nessuno spazio (passaggio)

    */

    if (!door) {
      // genero muro esterno
      let wall = new THREE.Mesh(geometryMuro, materialMuro);
      wall.receiveShadow = true;
      wall.castShadow = true;
      wall.rotation.set(0, rotation, 0);
      wall.position.set(this.x + xOffset, this.height / 2, this.z + zOffset);
      this.roomScene.add(wall);
      this.scene.collisionRaycasters.push(wall);
    
      wallblock.built = true;
      wallblock.type = "wall";

      //   this.closedSpaces++;
    } else {
      // 33% possibilità di fare un muro
      let rand = Math.floor(Math.random() * 3);
      // 25% di fare una multiporta
      let buildMultiDoor = Math.floor(Math.random() * 4);
      let meshes = "door.glb";
      if (buildMultiDoor == 0) {
        meshes = "multidoor.glb";
      }

      if (rand == 0) {
        wallblock.built = true;
        if (buildMultiDoor == 0) {
          wallblock.type = "multidoor";
          neighborwall.type = "multidoor";
        } else {
          wallblock.type = "singledoor";
          neighborwall.type = "singledoor";
        }

        // metodo asincrono che posiziona il muro
        loadWall(
          this.roomScene,
          meshes,

          this.x + xOffset,
          this.height / 2,
          this.z + zOffset,
          rotation + Math.PI / 2
        );
      }
    }
  }

  calculateSpace() {
    /** Assegno dei numeri rappresentanti un muro, utilizzato per la relativa rotazione successiva.  */
    if (this.topWall.type === "singledoor" || this.topWall.type === "wall") {
      this.spaces.push(0);
      this.spaces.push(0);
    }
    if (
      this.rightWall.type === "singledoor" ||
      this.rightWall.type === "wall"
    ) {
      this.spaces.push(1);
      this.spaces.push(1);
    }
    if (
      this.bottomWall.type === "singledoor" ||
      this.bottomWall.type === "wall"
    ) {
      this.spaces.push(2);
      this.spaces.push(2);
    }
    if (this.leftWall.type === "singledoor" || this.leftWall.type === "wall") {
      this.spaces.push(3);
      this.spaces.push(3);
    }
    this.remainingSpaces = this.spaces.length;
    return this.spaces.length;
  }

  // questa funzione riceve un array contenenti le informazioni dell'opera e le inserisce in una lista
  setArtwork(info) {
    if (
      info.tipo === "immagine" ||
      info.tipo === "video" 
     
    ) {
      this.artworks.push(info);
      this.remainingSpaces--;
    } else if (info.tipo === "3D") {
      this.artworks.push(info);

      this.remaining3DSpaces--;
    }
    else if(info == "empty" || info == "empty3D") {
      this.artworks.push(info);
    }

  }

  // chiamata asincrona per le dimensioni dell'immagine

  async placeArtwork() {
    var texture;
    var ratio;
    var horizontal = 0;
    var video;
    // deve variare in base alle dimensioni dell'opera
    var Operasize = !isMobile ? 3 : 2;
    for (var i = 0; i < this.artworks.length; i++) {
     
      if(this.artworks[i] === "empty") {
           ratio = 1;
           
      }
      if (this.artworks[i].tipo === "3D") {
        load3DMesh(
          this.artworks[i],
          this.x,
          this.z,
         
          this.roomScene
        );
      } else {
        if (this.artworks[i].tipo === "video") {
          video = document.createElement("video");
          video.playsInline = true;
          video.id = this.artworks[i].percorso;
          video.src = cartellaImmagini + this.artworks[i].percorso;
          texture = new THREE.VideoTexture(video);
          ratio = 4 / 3;
        } else if (this.artworks[i].tipo === "immagine") {
          texture = await loadTexture(
            cartellaImmagini + this.artworks[i].percorso
          );
          ratio = texture.image.naturalWidth / texture.image.naturalHeight;
          if(texture.image.naturalWidth > texture.image.naturalHeight) {
            horizontal = true;
          }
          else horizontal = false;
        } 
        var mmaterial = new THREE.MeshStandardMaterial({
          map: texture,
        });

       var geometryOpera;
       if(horizontal) {
        geometryOpera = new THREE.BoxBufferGeometry(
          Operasize,
          Operasize*(1/ratio),
          0.01
        );

       }
       else {
        geometryOpera = new THREE.BoxBufferGeometry(
          Operasize*(ratio),
          Operasize,
          0.01
        );

       }
      
        let opera = new THREE.Mesh(geometryOpera, mmaterial);

        // Se devo preparare un'opera normale
        if(this.artworks[i] !== "empty") {
        opera.title = "" + this.artworks[i].opera;
        opera.author = "" + this.artworks[i].autore;
        opera.description = this.artworks[i].descrizione + "";
        opera.artID = this.artworks[i].artID;
        opera.castShadow = false;
        
        }
        // se l'opera è 2D vuota
        else if(this.artworks[i] == "empty") {
        opera.title = "Appendi opera";
        opera.empty = true;
        opera.material = materialGreen;
        opera.load2DMesh = load2DMesh;
        }
        // se l'opera è 3D vuota

      
        // --------
        if (this.artworks[i].tipo === "video") {
          opera.video = video;
        } else if (this.artworks[i].tipo === "immagine") {
          opera.source = cartellaImmagini + this.artworks[i].percorso;
        } 

        // utilizzato per differenziare tra oggetti dinamici e ambiente 3D
        opera.artType = "artwork";
        // -------- calcolo il posizionamento dell'opera
        var xx = 0,
          zz = 0,
          rot = 0;

        var offset = i % 2 == 0 ? -this.width / 4 : this.width / 4;
        // muro sopra
        if (this.spaces[i] == 0) {
          xx = 0 + offset;
          zz = -this.width / 2 + 0.25;
          rot = 0;
        }
        // muro dx
        if (this.spaces[i] == 1) {
          xx = this.width / 2 - 0.25 * (this.width / 15);
          zz = 0 + offset;
          rot = Math.PI / 2;
        }
        // muro sotto
        if (this.spaces[i] == 2) {
          xx = 0 + offset;
          zz = this.width / 2 - 0.25 * (this.width / 15);
          rot = 0;
        }
        // muro sx
        if (this.spaces[i] == 3) {
          xx = -this.width / 2 + 0.25 * (this.width / 15);
          zz = 0 + offset;
          rot = Math.PI / 2;
        }

        opera.position.set(this.x + xx, isMobile ? 1.8 : 2.5, this.z + zz);
        opera.rot = rot;
        opera.rotation.set(0, rot, 0);
       
        // creo una mesh pronta per essere sostituita dal caricamento
        if(this.artworks[i] === "empty3D") {
          
          opera = new THREE.Mesh(new THREE.BoxGeometry(2,2,2), materialGreen);
          opera.title = "Carica modello 3D";
          opera.empty3D = true;
          opera.empty = true;
          opera.load3DMesh = load3DMesh;
          
          opera.x = this.x;
          opera.z = this.z;
          opera.position.set(this.x, 1, this.z);
        }
        opera.roomScene = this.roomScene;
        opera.collision = false;
        opera.interact = true;
        opera.remover = remover;
        this.roomScene.add(opera);
      }
    }
  }


}
