import * as THREE from "three";



// TEXTURE 
const textureMuro = new THREE.TextureLoader().load("whitewall.jpg");
textureMuro.encoding = THREE.LinearEncoding;
textureMuro.wrapS = THREE.RepeatWrapping;
textureMuro.wrapT = THREE.RepeatWrapping;
textureMuro.repeat.set(80, 80);
const texturePavimento = new THREE.TextureLoader().load("floor.jpg");
const roughnessPavimento = new THREE.TextureLoader().load(
  "floorRoughness.jpg"
);
// texturePavimento.encoding = THREE.LinearEncoding;
texturePavimento.wrapS = THREE.RepeatWrapping;
texturePavimento.wrapT = THREE.RepeatWrapping;
texturePavimento.repeat.set(4, 4);

// MATERIALI
const loader = new THREE.TextureLoader();


// pavimento
export var material = new THREE.MeshStandardMaterial({
  map: texturePavimento,
  

});
material.side = THREE.DoubleSide;
material.roughness = 0.05;
material.color = new THREE.Color(0.2,0.2,0.2);





// RAYTRACING
export function updateMap(texture) {
   material.envMap = texture;
  // materialMuro.envMap = texture;

}

export var materialGreen = new THREE.MeshStandardMaterial({transparent: true, opacity: 0.07, color: 0x00ff00});
//----------------
export var materialMuro = new THREE.MeshLambertMaterial({

 map: textureMuro
 // roughness: textureMuro,

});
let altc = 0xF0EBD8;
materialMuro.color.setHex(0xffffff);
materialMuro.side = THREE.DoubleSide;
var fragment = document.getElementById("fragment").textContent;
var vertex = document.getElementById("vertex").textContent;

const  displacement = new THREE.TextureLoader().load("displacementWater.jpg");

var time = 0;
export function updateShader() {
     time+=0.0001;
     time = time%0.5;
    shaderWater.uniforms.time.value = time;
     
}
export var shaderWater = new THREE.ShaderMaterial(
  {
    uniforms: {
      time:  { type: 'f', value: 0.0},
      displacement: {type: 't', value: displacement }
    },
    fragmentShader: fragment,
    vertexShader: vertex
  }
);
shaderWater.transparent = true;