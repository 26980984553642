// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getDatabase,
  ref,
  set,
  onDisconnect,
  onValue,
  remove
} from "firebase/database";
import { MD5 } from "crypto-js";
import { scene } from "../script";
import * as THREE from "three";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyC0DRy1NMugk3zENR8Gq9-5dJLuS4kbrDU",
  authDomain: "infinite-museum-b94f6.firebaseapp.com",
  projectId: "infinite-museum-b94f6",
  storageBucket: "infinite-museum-b94f6.appspot.com",
  messagingSenderId: "1089941854373",
  appId: "1:1089941854373:web:0a9eeae16ba2b7a8e1d54c",
  databaseURL:
    "https://infinite-museum-b94f6-default-rtdb.europe-west1.firebasedatabase.app",
};
import { myxlsx } from "../configurazione";
export var temporaryID = Math.floor(Math.random() * 10000000);
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// Get a reference to the database service
export const database = getDatabase(app);

export var reference = ref(database, "/Main/activeUsers/" + temporaryID);

export var artPathReference = ref(database, "/Main/paths/" + temporaryID);
export var usersSnapshot = ref(database, "/Main/activeUsers/");

export var users = [];
var values = [];
var IDs = [];
var newIDs = [];
var userGeo = new THREE.SphereGeometry(0.2, 16, 16);
var userMat = new THREE.MeshBasicMaterial({ color: 0xffff00 });
var realusers = 0;


//deleteDB();
// updateDB();
/*
var HOST = location.origin.replace(/^http/, 'ws');
HOST = HOST.replace("5000", '5001');
export var ws = new WebSocket(HOST);
ws.onopen = function (event) {};

var users;
ws.onmessage = function (event) {
 
  values = JSON.parse(event.data);
  console.log(event.data);
  newIDs = [];
  values.forEach((row) => {
    var k = row[0];
    newIDs.push(k);
    var found = checkId(IDs, k);
    if (!found) {
      // inserisco nuovo id tra i presenti

      IDs.push(k);

      // creo un nuovo visitatore
      var user = {
        id: k,
        position: [row[1], row[2]],
        mesh: new THREE.Mesh(userGeo, userMat),
        online: true,
      };
      user.mesh.castShadow = true;
      user.mesh.receiveShadow = true;
     users.push(user);
     if(k !== temporaryID)
     scene.add(user.mesh);
      // realusers++;
    }
    // aggiorno la posizione degli attuali visitatori
    else {
      users.forEach((user) => {
        if (user.id == row[0]) {
          user.position = [row[1], row[2]];
        }
      });
    }
  });
  users.forEach((user) => {
    if (!checkId(newIDs, user.id) && user.online) {
      user.online = false;
      scene.remove(user.mesh);
    }
    // aggiorno la mesh nella scena dell'utente
    else {
     
      user.mesh.position.set(user.position[0], 1.65, user.position[1]);
     
    }
  });
};
*/
/*
onValue(usersSnapshot, (snapshot) => {


 // console.log("utenti = "+realusers);
  newIDs = [];
 
  snapshot.forEach((row) => {
    
    var k = row.key;
    var found = checkId(IDs,k);
   
    // se è un nuovo id
    newIDs.push(k);
    if (!found) {
      // inserisco nuovo id tra i presenti

     IDs.push(k);
    
     
      // creo un nuovo visitatore
      var user = {
        id: k,
        position: row.val().position,
        mesh: new THREE.Mesh(userGeo, userMat),
        online: true
      };
      users.push(user);
      scene.add(user.mesh);
      realusers++;
    }
    // aggiorno la posizione degli attuali visitatori
    else {
      users.forEach(user => {
        if(user.id == row.key) {
          user.position = row.val().position
        }
      })
    }
  });
  
  
  

 
  // cancello utenti non più online
  users.forEach((user) => {
  
    
    if (!checkId(newIDs, user.id) && user.online ) {
     
 
      user.online = false;
     scene.remove(user.mesh)
   
     
    } 
    // aggiorno la mesh nella scena dell'utente
    else {
      user.mesh.position.set(user.position[0], 1.65, user.position[1]);
    }
  });
 
  



});
*/

function checkId(array, id) {
  var found = false;
  array.forEach((a) => {
    if (a === id) {
      found = true;
    }
  });

  return found;
}

// onDisconnect(reference).remove();




function deleteDB() {



    
  let artref =  remove(
   ref(database, "/Main/artworks/")
 );

 set( ref(database, "/Main/lastArtwork/"), {
   value: 0
 })
 
}

async function updateDB() {

 var opere = [];
 // assegno le opere in qualche modo alle stanze   debug.csv opere.csv

 // attendo che i modelli siano caricati
 var request = new XMLHttpRequest();
 request.open('GET', myxlsx, true);
 request.responseType = 'blob';
 request.onload = function () {
   var reader = new FileReader();
   reader.readAsBinaryString(request.response);
   reader.onload = function (e) {
     var data = e.target.result;
     var workbook = XLSX.read(data, {
       type: 'binary'
     });
     workbook.SheetNames.forEach(function (sheetName) {
       // Here is your object
       var objects = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
       objects.forEach((a, index) => {
         let p = a.percorso.replace(/\s+/g, '');
         let t = a.tipo.replace(/\s+/g, '');

         set(
           ref(database, "/Main/artworks/" + MD5(p + a.autore + index)),
           {
             opera: a.opera,
             autore: a.autore,
             descrizione: a.descrizione,
             percorso: p,
             tipo: t,
             artID: ""+MD5(p + a.autore + index)
           }

         )

       })

     })
};
 };
 request.send();





}